import React from 'react';

import GeneralLayout from '../layouts/GeneralLayout';
import SEO from '../components/seo';
import Container from "../components/Container";
import Heading from "../components/Heading";

const NotFoundPage = () => (
    <GeneralLayout>
        <SEO title="404: Not found"/>

        <Container>
            <Heading>404: Not Found</Heading>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Container>
    </GeneralLayout>
);

export default NotFoundPage;
